import React from 'react'

const LoginPage = () => {

    const Input = ({placeholder, type, id, name}) => {
        return (
            <input 
                type={type} 
                placeholder={placeholder} 
                id={id} 
                name={name}
                className='border-b border-[#666] mt-5 text-sm w-full py-2 outline-none'
            />
        )
    }

  return (
    <div className='login-container p-8 bg-white text-[--black]'>
        <div className=''>
            <a href='/' className='flex items-center'>
                <img src='https://i.imgur.com/l14GPSK.png' className='h-8'/>
                <h1 className='text-sm ml-2'>Swift Project</h1>
            </a>

            <div className='login-form-container'>
                <div className='flex flex-col'>
                    <h1 className='font-semibold text-xl'>Login to Swift Panel</h1>
                    <p className='text-sm font-light'>Manage your robot with our modern interface</p>

                    <div className='mt-7'>
                        <Input type='text' placeholder='Login' id='login' name='login'/>
                        <Input type='password' placeholder='Password' id='password' name='password'/>
                    </div>

                    <a href='/interface' className='text-white bg-[--black] py-3 flex justify-center font-semibold text-sm mt-12  rounded-full'>Login</a>
                </div>
            </div>
        </div>
        <div className='lg:flex items-end hidden rounded-2xl image-container '>
            <img src='https://i.imgur.com/xqTQQhj.png' className='rounded-2xl'/>
        </div>
    </div>
  )
}

export default LoginPage