import React from 'react'
import {Navbar2} from '../components/index'

const Project = () => {
  return (
    <div className='bg-white main-gradient h-screen text-[--black]'>
        <Navbar2 />
        <div className='project-container p-8'>
            <div className='opis-pro mr-14'>
                <h1 className='mb-12 text-xl font-semibold'>Swift Project</h1>
                <p className=' text-sm'>Projekt ten skupia się na stworzeniu innowacyjnego robota magazynującego, który rewolucjonizuje tradycyjne metody składowania i dystrybucji towarów.</p>
                <p className='mt-6 text-sm'>Projekt ten ma na celu zwiększenie efektywności operacyjnej w magazynach, eliminację błędów ludzkich i wprowadzenie nowoczesnych standardów w dziedzinie automatyzacji logistyki. Robot magazynujący stanowi wyraz nowatorskiego podejścia do składowania i transportu towarów, przyczyniając się do usprawnienia procesów magazynowych w firmach logistycznych i dystrybucyjnych.</p>
            </div>
            <div className='img bg-[#4C484530] mr-8 rounded-2xl flex items-center py-24 justify-center'>
                <img src='https://i.imgur.com/gWfPu5V.png' className='h-[320px]'/>
            </div>
        </div>
    </div>
  )
}

export default Project