import React from 'react'
import {Navbar2} from '../components/index'
import { TbPhotoSensor } from "react-icons/tb";
import { MdOutlineSensors, MdOutlineCloud, MdOutlineGpsNotFixed, MdOutlineSpeakerPhone } from "react-icons/md";
import { GiRobotGrab } from "react-icons/gi";
import { FaRegHardDrive } from "react-icons/fa6";
import { AiOutlineSafety } from "react-icons/ai";

const Solutions = () => {

    const Naglowek = ({icon, text}) => {
        return (
            <div className='flex items-center'>
                <div className='border rounded-xl text-lg border-[--black] p-3  '>{icon}</div>
                <h1 className='ml-2 font-medium'>{text}</h1>
            </div>
        )
    }

    const Text = ({text}) => {
        return (
            <div className='text-sm ml-14 mb-4'>{text}</div>
        )
    }

  return (
    <div className='text-[--black] bg-white'>
        <Navbar2 />
        <div className='solutions-container p-8'>
            <div className='opis-sol'>
                <h1 className='w-[75%]'>Robot magazynujący <p className='text-sm'>opisany wcześniej zawiera kilka innowacyjnych rozwiązań, które sprawiają, że jest zaawansowanym narzędziem w dziedzinie automatyzacji magazynowej. Oto kilka z tych innowacji:</p></h1>
            </div>
            <div className='image flex items-end justify-end mr-12 bg-[#61727E20] rounded-2xl'>
                <img src='https://i.imgur.com/jDmoByE.png' />
            </div>
            <div className='solutions'>
                <Naglowek text='Czujniki LIDAR' icon={<TbPhotoSensor />}/>
                <Text text='Zastosowanie zaawansowanych czujników LIDAR do skanowania otoczenia trójwymiarowego, co umożliwia robotowi precyzyjne nawigowanie w trudnych warunkach magazynowych, z dokładnością do milimetrów.'/>

                <Naglowek text='Czujniki Ultradźwiękowe' icon={<MdOutlineSensors />}/>
                <Text text='Wykorzystanie czujników ultradźwiękowych do detekcji przeszkód, co pozwala na bezpieczne unikanie kolizji w czasie rzeczywistym, nawet w obszarach o zmiennej konfiguracji.'/>

                <Naglowek text='Integracja z Systemem WMS' icon={<MdOutlineCloud />}/>
                <Text text='Kompleksowa integracja z systemem zarządzania magazynem (WMS), co umożliwia natychmiastową aktualizację informacji o stanie zapasów, co przekłada się na bardziej efektywne zarządzanie i planowanie.'/>

                <Naglowek text='GPS w Magazynie' icon={<MdOutlineGpsNotFixed />}/>
                <Text text='Wykorzystanie systemu GPS wewnątrz magazynu, co jest innowacyjnym podejściem do lokalizacji w zamkniętych przestrzeniach, umożliwiając precyzyjne śledzenie położenia robota.'/>

                <Naglowek text='Chwytaki Hydrauliczne' icon={<GiRobotGrab />}/>
                <Text text='Zastosowanie chwytaków hydraulicznych do manipulacji paczkami, co pozwala na precyzyjne i skuteczne przenoszenie różnych rodzajów towarów o zróżnicowanych kształtach i rozmiarach.'/>

                <Naglowek text='System Komunikacji' icon={<MdOutlineSpeakerPhone />}/>
                <Text text='Zintegrowany moduł komunikacyjny, który umożliwia bezprzewodową komunikację z centralnym serwerem magazynu, zapewniając natychmiastową wymianę informacji.'/>

                <Naglowek text='Oprogramowanie Sterujące' icon={<FaRegHardDrive />}/>
                <Text text='Wykorzystanie zaawansowanego oprogramowania sterującego, opartego na inteligencji sztucznej i algorytmach uczenia maszynowego, co pozwala na dynamiczne dostosowywanie się do zmieniających się warunków magazynowych.'/>

                <Naglowek text='Systemy Bezpieczeństwa' icon={<AiOutlineSafety />}/>
                <Text text='Zintegrowane systemy bezpieczeństwa, które automatycznie reagują na sytuacje awaryjne, zapewniając bezpieczeństwo zarówno dla ludzi, jak i samego robota.'/>
            </div>
        </div>
    </div>
  )
}

export default Solutions