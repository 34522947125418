import React from 'react'
import { useState } from 'react'
import { CiBarcode } from "react-icons/ci";

const Package = () => {

    const [show, setShow] = useState(false)

  return (
    <div className='w-full flex items-center justify-center flex-col h-calc'>
        {show ?
        <div className='flex w-full  items-center'>
            <div className='w-6/12 h-calc p-12'>
                <div className='flex items-center'>
                    <div className='border rounded-xl border-[#ffffff40] h-[25vh] p-8 w-6/12 mr-8'>
                        <div className='flex flex-col'>
                            <div className='flex justify-between'>
                            <div>
                            <div className='flex items-center'>
                                <CiBarcode className='text-lg mr-1 text-[--blue]'/> <h1 className='text-sm'>PL1234567890</h1>
                            </div>
                            <p className='text-[10px]    text-[#ffffff80]'>Package ID</p>
                            </div>
                            <div className='flex flex-col items-end'>
                                <div className='text-sm'>1.8kg</div>
                                <p className='text-[10px] text-[#ffffff80]'>Package weight</p>
                            </div>
                            </div>

                            <div className='flex w-full flex-col text-sm mt-8'>
                                <div className='flex justify-between items-center'>
                                    <h1 className='mr-2'>Robot ID</h1>
                                    <p className='text-[#ffffff80]'>HY693F5CVX</p>
                                </div>
                                <div className='flex justify-between mt-1 items-center'>
                                    <h1 className='mr-2'>Worker ID</h1>
                                    <p className='text-[#ffffff80]'>874130-CD</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='border rounded-xl border-[#ffffff40] h-[25vh] p-8 w-6/12'>
                        <div className='flex items-center justify-between'>
                        <div className='flex flex-col h-calc5 justify-between'>
                            <div>
                                <h1 className='text-sm'>14.4kg</h1>
                                <p className='text-[10px] opacity-50'>Package volume</p>
                            </div>
                            <div>
                                <h1 className='text-sm'>Glass</h1>
                                <p className='text-[10px] opacity-50'>Package content</p>
                            </div>
                        </div>

                        <div className='flex flex-col h-calc5 justify-between'>
                            <div>
                                <h1 className='text-sm'>40x45x40cm</h1>
                                <p className='text-[10px] opacity-50'>Package dimensions</p>
                            </div>
                            <div>
                                <div className='flex justify-between w-full rounded-full p-1 px-3 text-sm bg-[#FFA50040] text-[#FFA500]'>
                                    <div className='flex items-center w-full justify-between'>
                                        Waiting
                                        <div className='h-[18px] w-[18px] bg-[#c18d2e] rounded-full'/>
                                    </div>
                                </div>
                                <p className='text-[10px] opacity-50'>Package status</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className='w-full border border-[#ffffff40] h-[25vh] mt-8 p-8 rounded-xl'>
                    <div className='flex items-center text-sm'>
                        <div className='w-3/12'>Package ID</div>
                        <div className='w-3/12'>Robot ID</div>
                        <div className='w-2/12'>From</div>
                        <div className='w-2/12'>To</div>
                        <div className='w-3/12'>Delivery time</div>
                        <div className='w-2/12'>Content</div>
                    </div>
                    <div className='opacity-75'>
                    <div className='flex items-center text-[10px] mt-2'>
                        <div className='w-3/12'>DE2065068</div>
                        <div className='w-3/12'>HY693F5CVX</div>
                        <div className='w-2/12'>A1</div>
                        <div className='w-2/12'>B2</div>
                        <div className='w-3/12'>8mins</div>
                        <div className='w-2/12'>Clothes</div>
                    </div>
                    <div className='flex items-center text-[10px] mt-2'>
                        <div className='w-3/12'>UK2013637</div>
                        <div className='w-3/12'>HY693F5CVX</div>
                        <div className='w-2/12'>C1</div>
                        <div className='w-2/12'>A1</div>
                        <div className='w-3/12'>6mins</div>
                        <div className='w-2/12'>Electronics</div>
                    </div>
                    <div className='flex items-center text-[10px] mt-2'>
                        <div className='w-3/12'>CH9638094</div>
                        <div className='w-3/12'>HY693F5CVX</div>
                        <div className='w-2/12'>B2</div>
                        <div className='w-2/12'>C1</div>
                        <div className='w-3/12'>4mins</div>
                        <div className='w-2/12'>Glass</div>
                    </div>
                    <div className='flex items-center text-[10px] mt-2'>
                        <div className='w-3/12'>DE2315023</div>
                        <div className='w-3/12'>RH5819CJVD</div>
                        <div className='w-2/12'>A1</div>
                        <div className='w-2/12'>Loading zone</div>
                        <div className='w-3/12'>14mins</div>
                        <div className='w-2/12'>Clothes</div>
                    </div>
                    </div>
                </div>
            </div>
            <div className='w-6/12 h-calc p-12'>
                <div className='w-full p-8 border border-[#ffffff40] rounded-xl height'>

                </div>
            </div>
        </div>
        : 
        <>
        <div className='flex flex-col syne font-semibold mb-10 items-center'>
            <img src='https://i.imgur.com/URuMbz2.png' className='h-[100px]  mb-2'/>
            <h1 className='text-[#ffffffce] text-xl'>SWIFT</h1>
        </div>
        <div className='border rounded-xl flex items-center justify-between p-2 w-5/12'>
            <input type='text' placeholder='Type package ID' className='p-2 text-sm w-full bg-[#ffffff00] outline-none'/>
            <button onClick={()=>{setShow(true)}} className='px-2 border-l border-[#ffffff80] text-sm'>Track</button>
        </div>
        </>
        }
    </div>
  )
}

export default Package