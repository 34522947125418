import React from 'react'
import {Navbar2} from '../components/index'

const Teams = () => {
  return (
    <div className='bg-white main-gradient text-[--black] h-screen'>
        <Navbar2 />
        <div className='flex flex-col items-center h-calc-team justify-center w-full'>
            <div className='flex items-center my-4'>
                <p className='font-semibold mr-2'>Opracowanie koncepcji robota: </p><p className='opacity-75 '>Jakub Polański, Patryk Charmuszko</p>
            </div>
            <div className='flex items-center my-4'>
                <p className='font-semibold mr-2'>Modele 3D: </p><p className='opacity-75 '>Patryk Charmuszko</p>
            </div>
            <div className='flex items-center my-4'>
                <p className='font-semibold mr-2'>Strona Internetowa, Interfejs obsługi robota: </p><p className='opacity-75 '>Jakub Polański</p>
            </div>
            <div className='flex items-center my-4'>
                <p className='font-semibold mr-2'>Schematy działania: </p><p className='opacity-75 '>Jakub Polański, Patryk Charmuszko</p>
            </div>
        </div>
    </div>
  )
}

export default Teams