import React from 'react'
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowForward, IoIosArrowUp, IoMdSpeedometer } from "react-icons/io";
import { PiSteeringWheelFill } from "react-icons/pi";
import { GiRobotGrab } from "react-icons/gi";
import { BsArrow90DegRight } from "react-icons/bs";
import { CiBarcode } from "react-icons/ci";

const DrivePanel = (props) => {

  return (
    <div className='flex p-6 flex-col h-calc justify-between items-center'>
        <div className='flex w-4/12 absolute items-center justify-between'>
            <div className='flex items-center'>
            <BsArrow90DegRight className='text-4xl text-[--blue]'/>
            <div className='ml-4'>
            <h1>75m</h1>
            <p className='text-[12px] text-[#ffffff80]'>In 75m turn right</p>
            </div>
            </div>
            <div className='flex flex-col items-end'>
                <div className='flex items-center'>
                    <CiBarcode className='text-[--blue] text-3xl mr-2'/>
                    <p className='text-[12px]'>PL1234567890</p>
                </div>

                <h1 className='text-[12px] mt-2'>1.8kg</h1>
                <p className='text-[10px] text-[#ffffff80]'>Package weight</p>
            </div>
        </div>
        <div className='mb-8'>
        <img src='https://i.imgur.com/f8io6sN.png' className='mt-8'/>
        </div>
        <div className='flex justify-between items-center'>
            <div className={`h-[150px] flex justify-center items-center w-[150px] gradient2 ${props.checked ? "border-[#ffffff30]" :  "border-[#ffffff80]"} duration-200 border rounded-full`}>
            <IoIosArrowBack className='absolute cursor-pointer text-[#ffffff80] left-[57.75%] text-lg'/>
            <IoIosArrowDown className='text-lg cursor-pointer text-[#ffffff80] absolute top-[68.5%]'/>
            <IoIosArrowForward className='text-lg cursor-pointer text-[#ffffff80] absolute left-[65%]'/>
            <IoIosArrowUp className='text-lg cursor-pointer text-[#ffffff80] absolute top-[54%]'/>
                <div className='rounded-full flex  items-center justify-center bg-[--gray] h-[60px] w-[60px]'>
                <PiSteeringWheelFill className={`text-lg ${props.checked ? "text-[#ffffffaa]" : "text-[#ffffff]"} text-[#ffffffaa]`}/>
                </div>
            </div>

            <div className='px-12'>
                <div className={`border ${props.checked ? "border-[#ffffff40]" : "border-[#ffffff80]" } duration-200 flex items-center justify-center flex-col rounded-full h-[125px] w-[125px] p-4`}>
                    <h1 className='font-bold'>5</h1>
                    <p className='text-[12px] mt-[-2.5px] font-light text-[#ffffff80]'>km/h</p>
                    <IoMdSpeedometer className='mt-2 text-[--blue] text-lg'/>
                </div>
            </div>

            <div className={`h-[150px] ${props.checked ? "border-[#ffffff30]" :  "border-[#ffffff80]"} duration-200 flex justify-center items-center w-[150px] gradient2 border-[#ffffff30] border rounded-full`}>
            <IoIosArrowBack className='absolute cursor-pointer text-[#ffffff80] left-[82%] text-lg'/>
            <IoIosArrowDown className='text-lg cursor-pointer text-[#ffffff80] absolute top-[68.5%]'/>
            <IoIosArrowForward className='text-lg cursor-pointer text-[#ffffff80] absolute left-[89%]'/>
            <IoIosArrowUp className='text-lg text-[#ffffff80] cursor-pointer absolute top-[54%]'/>
                <div className='rounded-full flex items-center justify-center bg-[--gray] h-[60px] w-[60px]'>
                <GiRobotGrab className={`text-lg duration-200 ${props.checked ? "text-[#ffffffaa]" : "text-[#ffffff]"} `}/>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default DrivePanel