import React from 'react'
import {Routes, Route} from 'react-router-dom'
import {Interface, Main, LoginPage, Product, Solutions, Project, Teams} from './routes/index'

const App = () => {

  return (
    <div>
      <Routes>
        <Route path='/interface/*' element={<Interface />}/>
        <Route path='/login' element={<LoginPage />} />
        <Route path='/*' element={<Main />}/>
        <Route path='/product' element={<Product />}/>
        <Route path='/solutions' element={<Solutions />}/>
        <Route path='/project' element={<Project />}/>
        <Route path='/team' element={<Teams />}/>
      </Routes>
    </div>
  );
}

export default App;
