import React, { useState } from 'react'
import { AiFillHome } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa";
import { GoAlertFill } from "react-icons/go";
import { RiSettingsLine } from "react-icons/ri";
import { MdPowerSettingsNew } from "react-icons/md";
import { PiPackageFill } from "react-icons/pi";
import { IoMdClose } from "react-icons/io";
import { FaRegUser } from "react-icons/fa";
import { RiRobot2Line } from "react-icons/ri";
import { IoExitOutline } from "react-icons/io5";

const Navbar = () => {

    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)

    const NavItem = ({title, link, icon}) => {
        return (
            <a href={link} className='flex mx-4 duration-200 hover:bg-[--gray] items-center justify-center p-3 rounded-2xl w-[100px] h-[75px] flex-col  border border-[#ffffff30]'>
                {icon}
                <h1 className='text-[10px] text-[#efefef] mt-1.5'>{title}</h1>
            </a>
        )
    }

    const Naglowek = ({title}) => {
        return (
            <div className='w-3/12 text-sm'>{title}</div>
        )
    }

    const Item = ({title}) => {
        return (
            <div className='w-3/12 text-[12px]'>{title}</div>
        )
    }

    const Items = ({nr, weight, from, to, date}) => {
        return (
            <div className='flex px-4 py-2'>
                <Item title={nr}/>
                <Item title={weight}/>
                <Item title={from}/>
                <Item title={to}/>
                <Item title={date}/>
            </div>
        )
    }

    const Profile = () => {
        return (
            <div className='absolute w-full top-0 flex justify-center items-center left-0 h-screen bg-filter bg-[#00000090]'>
                <div className='w-5/12 border rounded-2xl bg-[--black] p-6'>
                    <div className='flex justify-between items-center'>
                        <h1>Profile</h1>
                        <button onClick={()=>{setShow2(false)}} className='text-xl'><IoMdClose /></button>
                    </div>

                    <div className='flex mt-6 items-center'>
                        <div className='h-[45px] w-[45px] border rounded-xl flex mr-2 items-center justify-center'><FaRegUser className=''/></div> <h1 className='mr-2'>Worker</h1> <p>874130-CD</p>
                    </div>

                    <div className='flex items-center mb-1 mt-2'><p className='mr-1'>Krzysztof</p><p>Kowalski</p></div>
                    <div className='flex items-center'><h1 className='mr-1 text-[#6EFF99    ]'>Login: </h1><p>krzysztofkowalski87</p></div>
                    
                    <div className='flex items-center mt-6'>
                    <div className='h-[45px] w-[45px] border rounded-xl flex mr-2 items-center justify-center'><RiRobot2Line className=''/></div> <p className='mr-2'>Robot</p> <h1>HY693F5CVX</h1>
                    </div>

                    <div className='w-3/12 mt-2'>
                        <h1 className='text-sm mb-1 mt-3'>Status</h1>
                        <div className='bg-[#6eff9940] flex items-center justify-between rounded-full py-1 px-3  text-[#6eff99]'>
                            Delivering
                            <div className='bg-[#6eff99] p-2 rounded-full'/>
                        </div>
                    </div>

                    <div className='mt-4'>
                        <h1 className='syne text-lg'>History of packages</h1>

                        <div className='flex items-center mt-4 border rounded-t-xl border-[#ffffff40] py-2 px-4 w-full'>
                            <Naglowek title='Nr'/>
                            <Naglowek title='Weight'/>
                            <Naglowek title='From'/>
                            <Naglowek title='To'/>
                            <Naglowek title='Date'/>
                        </div>
                        <div className='flex flex-col border-b border-l border-r border-[#ffffff40] rounded-b-xl'>
                            <Items nr="DE2065068" weight="4kg" from="A1" to="B2" date="10-02-2023"/>
                            <Items nr="UK2013637" weight="0.5kg" from="C1" to="A1" date="21-08-2023"/>
                            <Items nr="RPA836595" weight="2.1kg" from="B1" to="Loading zone" date="03-03-2023"/>
                            <Items nr="CH9638094" weight="3.15kg" from="B2" to="C1" date="18-11-2023"/>
                            <Items nr="PL31864287" weight="1.68kg" from="B1" to="Loading zone" date="16-06-2023"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

  return (
    <div>

        {show2 ? <Profile /> : null}
        
        {show ? 
    <div className='absolute flex items-center justify-center top-0 left-0 bg-[#00000090] w-full h-screen'>
        <div className='w-5/12 syne p-12 rounded-2xl border border-[--pink] bg-filter bg-[rgb(0,0,0,0.25)]'>
            <h1 className='text-xl mb-6'>Are you sure you want to shutdown the robot?</h1>
            <button className='px-6 mr-6  py-3 bg-[--pink] text-[--black] border border-[--pink] rounded-2xl' onClick={()=>{setShow(false)}}>Shutdown</button>
            <button className='px-6  py-3 text-[--pink] border border-[--pink] rounded-2xl' onClick={()=>{setShow(false)}}>Cancel</button>
        </div>
    </div>
    : null}
    <div className='rounded-3xl flex items-center justify-between border border-[#ffffff30] gradient-bottom px-8 py-4'>
        <div className='flex flex-col syne items-center'>
            <img src='https://i.imgur.com/URuMbz2.png' className='h-10  '/><h1 className='mt-1 text-xl text-[#ffffffce] font-bold'>SWIFT</h1>
        </div>

        <div className='flex items-center'>
        <NavItem title='Home' link='/interface' icon={<AiFillHome className='text-lg'/>}/>
        <NavItem title='Map' link='/interface/map' icon={<FaMapMarkerAlt className='text-lg'/>}/>
        <NavItem title='Damage' link='/interface/damage' icon={<GoAlertFill className='text-lg'/>}/>
        <NavItem title='Track package' link='/interface/track-package' icon={<PiPackageFill className='text-lg'/>}/>
        </div>

        <div className='flex text-lg'>
        <RiSettingsLine onClick={()=>{setShow2(!show)}} className='mr-4 cursor-pointer' />
        <MdPowerSettingsNew onClick={()=>{setShow(!show)}} className='mr-4 cursor-pointer'/>
        <a href='/'><IoExitOutline className='cursor-pointer'/></a>
        </div>
    </div>
    </div>
  )
}

export default Navbar