import React from 'react'
import {Home, Navbar, Map, Damage, Package} from '../components/index'
import {Routes, Route} from 'react-router-dom'

const Interface = () => {


  return (
    <div>
        <div className='flex flex-col justify-between h-screen p-8'>
        <div>
            <Routes>
             <Route path='/' element={<Home />} />
             <Route path='/map' element={<Map />} />
             <Route path='/damage' element={<Damage />} />
             <Route path='/track-package' element={<Package />} />
            </Routes>
        </div>
        <Navbar />
        </div>
    </div>
  )
}

export default Interface