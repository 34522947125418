import React from 'react'
import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'

const products = [
  { name: 'Analytics', description: 'Get a better understanding of your traffic', href: '#', icon: ChartPieIcon },
  { name: 'Engagement', description: 'Speak directly to your customers', href: '#', icon: CursorArrowRaysIcon },
  { name: 'Security', description: 'Your customers’ data will be safe and secure', href: '#', icon: FingerPrintIcon },
  { name: 'Integrations', description: 'Connect with third-party tools', href: '#', icon: SquaresPlusIcon },
  { name: 'Automations', description: 'Build strategic funnels that will convert', href: '#', icon: ArrowPathIcon },
]
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Navbar = () => {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="text-[--black]">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Swift Project</span>
            <img className="h-8 w-auto" src="https://i.imgur.com/l14GPSK.png" alt="" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-[--black]"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <Popover className="relative">
            <a href='/product' className="flex items-center gap-x-1 text-sm font-medium leading-6 ">
              Produkt
            </a>
          </Popover>

          <a href="/solutions" className="text-sm font-medium leading-6">
            Rozwiązania
          </a>
          <a href="/project" className="text-sm font-medium leading-6">
            O projekcie
          </a>
          <a href="/team" className="text-sm font-medium leading-6">
            Zespół
          </a>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="/login" className="text-sm font-medium leading-6">
            Log in <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden text-[--black]" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-[#ffffff10] navbar-background-blur px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src="https://i.imgur.com/l14GPSK.png"
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6">
              <div className="space-y-2 pt-6">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <a href='/product' className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-sm  font-medium leading-7">
                        Produkt
                      </a>
                    </>
                  )}
                </Disclosure>
                <a
                  href="/solutions"
                  className="-mx-3 block rounded-lg px-3 py-2  text-sm font-medium leading-7"
                >
                  Rozwiązania
                </a>
                <a
                  href="/project"
                  className="-mx-3 block rounded-lg px-3 py-2  text-sm font-medium leading-7"
                >
                  O projekcie
                </a>
                <a
                  href="/team"
                  className="-mx-3 block rounded-lg px-3 py-2  text-sm font-medium leading-7"
                >
                  Zespół
                </a>
              </div>
              <div className="">
                <a
                  href="/login"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-sm font-medium leading-7"
                >
                  Log in
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}

export default Navbar