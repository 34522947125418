import React from 'react'
import { RiRobot2Fill } from "react-icons/ri";
import { LiaPalletSolid } from "react-icons/lia";

const Map = () => {
  return (
    <div className='w-full h-calc border rounded-2xl border-[#ffffff40] p-12'>
        <div className='flex items-center justify-between w-full syne text-lg text-[--blue]'>
            <div className='flex items-center w-9/12'>
            <div className=' border-t gradient3 border-b border-[#ffffff40] h-calc3 w-2/12 mr-24 rounded-2xl flex items-center justify-center'>A1</div>
            <div className=' border-t gradient3 border-b border-[#ffffff40] h-calc3 w-2/12 mr-24 rounded-2xl flex items-center justify-center'>A2</div>
            <div className='  rounded-full flex items-center justify-center absolute gradient4 p-3 left-[15.5%] top-[30%]'><RiRobot2Fill className='text-[--black]'/></div>
            <div className=' border-t gradient3 border-b border-[#ffffff40] h-calc3 w-2/12 mr-24 rounded-2xl flex items-center justify-center'>B1</div>
            <div className=' border-t gradient3 border-b border-[#ffffff40] h-calc3 w-2/12 mr-24 rounded-2xl flex items-center justify-center'>B2</div>
            <div className=' border-t gradient3 border-b border-[#ffffff40] h-calc3 w-2/12  rounded-2xl flex items-center justify-center'>C1</div>
            </div>
            <div className='w-2/12 text-4xl '>
                <div className='border-[#ffffff40] flex items-center justify-center w-full p-8 border-2 rounded-2xl border-dashed h-[27vh]'><LiaPalletSolid /></div>
                <div className='border-[#ffffff40] flex items-center justify-center w-full p-8 border-2 rounded-2xl border-dashed mt-8 h-[27vh]'><LiaPalletSolid /></div>
            </div>
        </div>
    </div>
  )
}

export default Map