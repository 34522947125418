import React from 'react'
import { GoAlertFill } from "react-icons/go";
import { PiSteeringWheelFill } from "react-icons/pi";
import { TbPhotoSensor } from "react-icons/tb";
import { MdOutlineSensors, MdSettingsRemote } from "react-icons/md";
import { GiRobotGrab } from "react-icons/gi";
import { FaRegLightbulb } from "react-icons/fa";
import { CgCompressRight } from "react-icons/cg";
import { FaLocationCrosshairs } from "react-icons/fa6";

const Damage = () => {

    const Good = () => {
        return (
            <div className=' w-3/12   font-semibold text-[#00FF7F]'><div className='w-3/12 py-1.5 flex items-center rounded-2xl px-3 bg-[#00FF7F40]'> <div className='bg-[#00ff1a9e] h-[15px] w-[15px] rounded-2xl ml-2'/></div></div>
        )
    }

  return (
    <div>
        <div className='flex items-center'>
            <GoAlertFill className='text-xl'/><h1 className='ml-2 font-light text-lg'>Damage</h1>
        </div>

        <div className='mt-6'>
            <div className='flex border border-[#ffffff40] uppercase rounded-t-xl py-3 syne px-4 w-full items-center'>
                <div className='w-3/12'>Component</div>
                <div className='w-[20.5%]'>Usage</div>
                <div className='w-3/12'>Energy consumption</div>
                <div className='w-3/12'>Status</div>
            </div>
            <div className='py-2 text-sm font-light px-4 border-l border-r border-[#ffffff40] border-b rounded-b-xl'>
            <div className='flex w-full py-2 items-center'>
                <div className='w-3/12 flex items-center'><PiSteeringWheelFill className='text-lg mr-2 text-[--blue]'/> Running gear</div>
                <div className='w-3/12 rounded-full font-semibold text-[--green]'>Used</div>
                <div className='w-[20.5%]'>23%</div>
                <div className=' w-3/12   font-semibold text-[--green]'><div className='w-5/12 flex justify-between py-1.5 items-center rounded-2xl px-3 bg-[--green-bg]'> Good<div className='bg-[--green-dot] h-[15px] w-[15px] rounded-2xl ml-2'/></div></div>
            </div>
            <div className='flex w-full py-2 items-center'>
                <div className='w-3/12 flex items-center'><TbPhotoSensor className='mr-2 text-lg text-[--blue]'/> Lidar sensor</div>
                <div className='w-3/12 rounded-full font-semibold text-[--green]'>Used</div>
                <div className='w-[20.5%]'>23%</div>
                <div className=' w-3/12   font-semibold text-[--green]'><div className='w-5/12 flex justify-between py-1.5 items-center rounded-2xl px-3 bg-[--green-bg]'> Good<div className='bg-[--green-dot] h-[15px] w-[15px] rounded-2xl ml-2'/></div></div>
            </div>
            <div className='flex w-full py-2 items-center'>
                <div className='w-3/12 flex items-center'><MdOutlineSensors className='mr-2 text-lg text-[--blue]'/> Ultrasonic sensors</div>
                <div className='w-3/12 rounded-full font-semibold text-[--green]'>Used</div>
                <div className='w-[20.5%]'>6%</div>
                <div className=' w-3/12   font-semibold text-[--green]'><div className='w-5/12 flex justify-between py-1.5 items-center rounded-2xl px-3 bg-[--green-bg]'> Good<div className='bg-[--green-dot] h-[15px] w-[15px] rounded-2xl ml-2'/></div></div>
            </div>
            <div className='flex w-full py-2 items-center'>
                <div className='w-3/12 flex items-center'><GiRobotGrab className='mr-2 text-lg text-[--blue]'/> Gripper</div>
                <div className='w-3/12 text-[#9eb4ca] font-semibold'>Not used</div>
                <div className='w-[20.5%]'>0%</div>
                <div className=' w-3/12   font-semibold text-[#9eb4ca]'><div className='w-5/12 flex justify-between py-1.5 items-center rounded-2xl px-3 bg-[#9eb4ca30]'> Unavaliable<div className='bg-[#9eb4cac8] h-[15px] w-[15px] rounded-2xl ml-2'/></div></div>
            </div>
            <div className='flex w-full py-2 items-center'>
                <div className='w-3/12 flex items-center'><FaRegLightbulb className='mr-2 text-lg text-[--blue]'/> Lights</div>
                <div className='w-3/12 rounded-full font-semibold text-[--green]'>Used</div>
                <div className='w-[20.5%]'>7%</div>
                <div className=' w-3/12   font-semibold text-[--green]'><div className='w-5/12 flex justify-between py-1.5 items-center rounded-2xl px-3 bg-[--green-bg]'> Good<div className='bg-[--green-dot] h-[15px] w-[15px] rounded-2xl ml-2'/></div></div>
            </div>
            <div className='flex w-full py-2 items-center'>
                <div className='w-3/12 flex items-center'><CgCompressRight className='mr-2 text-lg text-[--blue]'/> Pressure sensor</div>
                <div className='w-3/12 text-[#9eb4ca] font-semibold'>Not used</div>
                <div className='w-[20.5%]'>0%</div>
                <div className=' w-3/12   font-semibold text-[#9eb4ca]'><div className='w-5/12 flex justify-between py-1.5 items-center rounded-2xl px-3 bg-[#9eb4ca30]'> Unavaliable<div className='bg-[#9eb4cac8] h-[15px] w-[15px] rounded-2xl ml-2'/></div></div>
            </div>
            <div className='flex w-full py-2 items-center'>
                <div className='w-3/12 flex items-center'><FaLocationCrosshairs className='mr-2 text-lg text-[--blue]'/> GPS</div>
                <div className='w-3/12 rounded-full font-semibold text-[--green]'>Used</div>
                <div className='w-[20.5%]'>4%</div>
                <div className=' w-3/12  font-semibold text-[--green]'><div className='w-5/12 flex justify-between py-1.5 items-center rounded-2xl px-3 bg-[--green-bg]'> Good<div className='bg-[--green-dot] h-[15px] w-[15px] rounded-2xl ml-2'/></div></div>
            </div>
            <div className='flex w-full py-2 items-center'>
                <div className='w-3/12 flex items-center'><MdSettingsRemote className='mr-2 text-lg text-[--blue]'/> Remote control module</div>
                <div className='w-3/12 text-[#9eb4ca] font-semibold'>Not used</div>
                <div className='w-[20.5%]'>0%</div>
                <div className=' w-3/12   font-semibold text-[#9eb4ca]'><div className='w-5/12 flex justify-between py-1.5 items-center rounded-2xl px-3 bg-[#9eb4ca30]'> Unavaliable<div className='bg-[#9eb4cac8] h-[15px] w-[15px] rounded-2xl ml-2'/></div></div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Damage