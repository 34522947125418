import React from 'react'
import {Navbar2} from '../components/index'

const Main = () => {

  return (
    <div>
      <div className='main-container main-gradient bg-white text-[--black]'>
        <Navbar2 />
        <div className='h-calc-main lg:p-12 p-4 flex flex-col justify-between'>
          <div className='flex items-center justify-center'> 
            <img src='https://i.imgur.com/ajAgLVl.png' className='opacity-75'/>
          </div>
          <div className='w-full flex justify-center items-center'>
            <h1 className='text-8xl font-bold'>SWIFT PROJECT</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main