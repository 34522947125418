import React, {useState} from 'react'
import { FaWind } from "react-icons/fa";
import { PiEngineFill, PiSteeringWheelFill } from "react-icons/pi";
import { RiRobot2Fill } from "react-icons/ri";
import {DrivePanel} from './index'
import { MdBolt } from "react-icons/md";
import { MdOutlineSensors, MdOutlineDisplaySettings } from "react-icons/md";
import { GiRobotGrab } from "react-icons/gi";

const Home = () => {

    const [temp, setTemp] = useState('18')

    const [checked, setChecked] = useState(false)
    const [checked2, setChecked2] = useState(false)
    


  return (
    <div className='flex'>
        <div className='w-6/12 flex'>
            <div className='flex w-5/12 flex-col justify-center'>
            <div className='flex justify-center flex-col items-center'>
                <div className='border-[6px] rounded-full p-5 border-dotted'>
                    <div className='flex flex-col rounded-full gradient items-center border-t justify-center w-[175px] h-[175px] bg-[--gray]'>
                        <h1 className='text-4xl font-semibold'>{temp}°</h1>
                        <p className='text-sm mt-1 syne text-[#cccccc]'>Temperature</p>
                    </div>
                </div>

                <div className='mt-7'>
                    <button onClick={()=>{setTemp('18')}} className='p-4 hover:border-white border border-[#ffffff00] hover:bg-[#2a2b2d] duration-200 bg-[--gray] rounded-xl mx-4'><FaWind /></button>
                    <button onClick={()=>{setTemp('45')}} className='p-4 hover:border-white border border-[#ffffff00] hover:bg-[#2a2b2d] duration-200 bg-[--gray] rounded-xl mx-4'><PiEngineFill /></button>
                    <button onClick={()=>{setTemp('30')}} className='p-4 hover:border-white border border-[#ffffff00] hover:bg-[#2a2b2d] duration-200 bg-[--gray] rounded-xl mx-4'><RiRobot2Fill /></button> 
                </div>
            </div>

            <div className=''>
                <div className='border flex items-center p-2 rounded-2xl mt-8'>
                    <div className='bg-[--blue]  w-2/12 mr-3 py-11 rounded-xl'></div>
                    <div className='bg-[--blue]  w-2/12 mr-3 py-11 rounded-xl'></div>
                    <div className='bg-[--blue]  w-2/12 mr-3 py-11 rounded-xl'></div>
                    <div className='border w-2/12 mr-3 py-11 rounded-xl'></div>
                    <div className='border w-2/12 py-11 rounded-xl'></div>
                </div>

                <div className='flex items-center mt-1.5'>
                    <MdBolt className='text-xl text-[--blue]'/>
                    <h1 className='text-sm text-[#ffffff80]'>{checked ? "9hrs 30mins left" : "7hrs left"}</h1>
                </div>
            </div>
            </div>

            <div className='w-6/12 flex flex-col justify-between ml-10'>
                <div>
                <div className='flex items-center'>
                    <div className='rounded-2xl p-4 mr-4 border border-[#ffffff80]'>
                        <PiEngineFill className='text-lg'/>
                    </div>
                    <div className='w-full'>
                        <div className='flex mb-2 justify-between items-center'>
                        <h1 className='syne'>Drive</h1>
                        <p className='text-sm'>{checked ? "35%" : "45%"}</p>
                        </div>
                        <div className='rounded-2xl bg-[#ffffff80]'>
                            <div className={`p-0.5 ${checked ? "w-[35%]" : "w-[45%]"} duration-200 rounded-full bg-[--green]`}/>
                        </div>
                    </div>
                    
                </div>

                <div className='flex my-6 items-center'>
                    <div className='rounded-2xl p-4 mr-4 border border-[#ffffff80]'>
                        <MdOutlineSensors className='text-lg'/>
                    </div>
                    <div className='w-full'>
                        <div className='flex mb-2 justify-between items-center'>
                        <h1 className='syne'>Sensors</h1>
                        <p className='text-sm'>{checked ? "50%" : "65%"}</p>
                        </div>
                        <div className='rounded-2xl bg-[#ffffff80]'>
                            <div className={`p-0.5 ${checked ? "w-[50%]" : "w-[65%]"} duration-200 rounded-full bg-[--green]`}/>
                        </div>
                    </div>
                    
                </div>

                <div className='flex my-6 items-center'>
                    <div className='rounded-2xl p-4 mr-4 border border-[#ffffff80]'>
                        <GiRobotGrab className='text-lg'/>
                    </div>
                    <div className='w-full'>
                        <div className='flex mb-2 justify-between items-center'>
                        <h1 className='syne'>Sensors</h1>
                        <p className='text-sm'>{checked ? "10%" : "20%"}</p>
                        </div>
                        <div className='rounded-2xl bg-[#ffffff80]'>
                            <div className={`p-0.5 ${checked ? "w-[10%]" : "w-[20%]"} duration-200 rounded-full bg-[--green]`}/>
                        </div>
                    </div>
                    
                </div>

                <div className='flex my-6 items-center'>
                    <div className='rounded-2xl p-4 mr-4 border border-[#ffffff80]'>
                        <MdOutlineDisplaySettings className='text-lg'/>
                    </div>
                    <div className='w-full'>
                        <div className='flex mb-2 justify-between items-center'>
                        <h1 className='syne'>System</h1>
                        <p className='text-sm'>{checked ? "23%" : "32%"}</p>
                        </div>
                        <div className='rounded-2xl bg-[#ffffff80]'>
                            <div className={`p-0.5 ${checked ? "w-[23%]" : "w-[32%]"} duration-200 rounded-full bg-[--green]`}/>
                        </div>
                    </div>
                    
                </div>
                </div>

                <div>
                    <div className='rounded-2xl bg-[--gray] w-6/12 p-3 flex flex-col justify-center'>
                        <div className='flex items-center'>
                            <div className='bg-[--green] rounded-xl p-2.5 text-[--black]'><MdBolt className='text-xl'/></div>
                            <h1 className='text-sm ml-2'>Economy mode</h1>
                        </div>
                        <div className='flex syne mt-3 justify-between items-center'>
                            <h1 className='text-sm font-semibold text-[#ffffff80]'>{checked ? "ON" : "OFF"}</h1>
                            <input onClick={()=>{setChecked(!checked)}} type='checkbox' id='switch'/><label for="switch">Toggle</label>
                        </div>
                    </div>

                    <div className='rounded-2xl bg-[--gray] mt-2 w-6/12 p-3 flex flex-col justify-center'>
                        <div className='flex items-center'>
                            <div className='bg-[--green] rounded-xl p-2.5 text-[--black]'><PiSteeringWheelFill className='text-xl'/></div>
                            <h1 className='text-sm ml-2'>Manual driving</h1>
                        </div>
                        <div className='flex syne mt-3 justify-between items-center'>
                            <h1 className='text-sm font-semibold text-[#ffffff80]'>{checked2 ? "ON" : "OFF"}</h1>
                            <input onClick={()=>{setChecked2(!checked2)}} type='checkbox'  id='switch2'/><label for='switch2'>Toggle</label>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
        <div className='w-6/12'>
            <DrivePanel checked={!checked2}/>
        </div>

       
    </div>
  )
}

export default Home