import React from 'react'
import {Navbar2} from '../components/index'

const Product = () => {

    const Naglowek = ({title}) => {
        return (
            <div className='text-sm my-1 font-mdeium'>{title}</div>
        )
    }

    const Tekst = ({title}) => {
        return (
            <div className='text-[10px] opacity-80'>{title}</div>
        )
    }

  return (
    <div className='text-[--black] bg-white'>
        <Navbar2 />
        <div className='product-container px-8 py-3 text-[--black]'>
            <div className='opis'>
                <h1 className='font-semibold'>Swift robot</h1>
                <p className='text-[12px] mt-3'>Robot magazynujący o wymiarach 50x50 cm to zaawansowane urządzenie automatyzujące procesy składowania i transportu w magazynach. Wyposażony w zaawansowane czujniki, robot samodzielnie przemieszcza się po obszarze magazynowym, skanując otoczenie w czasie rzeczywistym. Jego zdolność do wożenia paczek o różnych rozmiarach sprawia, że jest wszechstronny i efektywny w obsłudze różnorodnych towarów.</p>
                <p className='text-[12px] mt-3'>Roboty magazynujące tego typu reprezentują nowoczesne podejście do logistyki i magazynowania, przyczyniając się do zwiększenia efektywności operacyjnej i minimalizacji błędów ludzkich w procesach składowania i dystrybucji towarów.</p>
            </div>
            <div className='specs'>
                <Naglowek title='Wymiary'/>
                <Tekst title='Wysokość: 50 cm'/>
                <Tekst title='Szerokość: 50 cm'/>

                <Naglowek title='Ruch i manewrowanie'/>
                <Tekst title='Napęd: Koła umożliwiające swobodne manewrowanie'/>
                <Tekst title='Czujniki odległości: Czujniki ultradźwiękowe do precyzyjnego unikania przeszkód'/>

                <Naglowek title='System Wizyjny'/>
                <Tekst title='Czujniki LIDAR: Zaawansowany system Lidar do skanowania otoczenia i tworzenia mapy trójwymiarowej'/>
                <Tekst title='Kamera: Wbudowana kamera do monitorowania otoczenia i identyfikacji paczek'/>

                <Naglowek title='Lokalizacja i Nawigacja'/>
                <Tekst title='GPS: Globalny system pozycjonowania dla precyzyjnej lokalizacji w magazynie'/>

                <Naglowek title='Chwytaki Hydrauliczne'/>
                <Tekst title='Mechanizm chwytający: Chwytaki hydrauliczne do precyzyjnego podnoszenia, przenoszenia i układania paczek'/>

                <Naglowek title='Zasilanie'/>
                <Tekst title='Źródło zasilania: Akumulatory o dużej pojemności, zapewniające długotrwałą pracę bez konieczności częstego ładowania'/>

                <Naglowek title='Oprogramowanie'/>
                <Tekst title='System operacyjny: Specjalnie dostosowany system operacyjny do sterowania ruchem, przetwarzania danych z czujników i komunikacji z serwerem'/>
            </div>
            <div className='render flex justify-center items-center rounded-2xl '>
                <img src='https://i.imgur.com/WUFr8vs.png' className='opacity-75'/>
            </div>
        </div>
    </div>
  )
}

export default Product